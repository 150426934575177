import React,{lazy,Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { Provider } from 'react-redux';
import store from './store/index';
import { Toaster } from 'react-hot-toast';


const App = lazy(() => import('./App'))
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Provider store={store}>
  <Suspense>
    <App />
    <Toaster 
    
    toastOption={{
      position: "top-center",
      autoClose: 5000,
      style: {
        background: '#3936df',
        color: '#fff',
        borderRadius: '5px',
      },
      progress: {
        color: '#fff',
      },
    }}
    
    />
    </Suspense>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
