import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducers";


const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
    {
        return getDefaultMiddleware({
            serializableCheck: false,
            // immutableCheck: false,
            // thunk: true,
            // other middleware like `logger` and `redux-persist`
        })
    },
    // devTools: process.env.NODE_ENV === 'development',
    devTools:true
})
export default store
