import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from './../../api/api';

export const admin_login = createAsyncThunk(
   'auth/admin_login',
   async(info,{rejectWithValue, fulfillWithValue})=>{
      console.log(info)
      try {
         // Simulate API call
         const {data} = await api.post('/admin-login',info, { withCredentials: true }) 
         localStorage.setItem('accessToken',data.token)  
            console.log (data)
            return fulfillWithValue(data)
      
      } catch (error) {
         return rejectWithValue(error.reponse.data)    
      }
   }
);
export const authReducer = createSlice({
   name: 'auth',
   initialState: {
      successMessage:'',
      errorMessage: '',
      loader: false,
      userInfo: ''
   },
   reducers: {
      MessageClear :(state,_)=>{
         state.successMessage = '';
         state.errorMessage = '';
      }

   },
      extraReducers: (builder) => {
         
         builder
         .addCase(admin_login.pending, (state, { payload }) => {
             state.loader = true;
         })
         .addCase(admin_login.rejected, (state, { payload }) => {
             state.loader = false;
              state.errorMessage = "error";
         }) 
         .addCase(admin_login.fulfilled, (state, { payload }) => {
             state.loader = false;
             state.successMessage = payload.successMessage
              state.token = payload.token
            //   state.role = returnRole(payload.token)
         })
 
     
      },
   
});
export const {MessageClear}= authReducer.actions;

export default authReducer.reducer;
